<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Log On/OFF dịch vụ</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Danh mục">
              <b-form-select
                v-model="input.type"
                :options="options.type"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tên dịch vụ/ sản phẩm">
              <b-form-input v-model="input.title"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tên dịch vụ/ sản phẩm">
              <b-form-input v-model="input.title"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Từ ngày">
              <b-form-input v-model="input.from" :type="`date`"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Đến ngày">
              <b-form-input v-model="input.to" :type="`date`"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button
              class="mr-2"
              variant="outline-primary"
              @click="listAll(true)"
              >Tìm kiếm </b-button
            >&nbsp;
          </b-col>
        </b-row>
        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
const CmsRepository = RepositoryFactory.get("cms");
// const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      fields: [
        { action: "Hành động" },
        { type: "Danh mục" },
        { title: "Tên dịch vụ/ sản phẩm" },
        { created_by: "Người thực hiện" },
        { created_at: "Thời gian" },
      ],
      input: {
        title: null,
        from: null,
        to: null,
      },
      options: {
        type: [
          { value: "DISCOUNT", text: "Chiết khấu" },
          { value: "PRODUCT", text: "Sản phẩm" },
          { value: "TOPUP", text: "TOPUP" },
          { value: "SERVICE", text: "Dịch vụ" },
        ],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Log config dịch vụ", route: "serviceLog" },
    ]);
  },
  methods: {
    listAll(query = null) {
      let params = {};
      if (query != null) {
        params = {
          title: this.input.title,
          from: this.input.from,
          to: this.input.to,
          type: this.input.type,
        };
      }
      this.$bus.$emit("show-loading", true);
      params = this.removeValidateNull(params);
      CmsRepository.listLogActionService(params)
        .then((response) => {
          this.$bus.$emit("show-loading", false);
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
          } else {
            this.notifyAlert("success", "Lấy thông tin thành công");
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.total;
            this.paginate.totalPage = response.data.data.last_page;
            this.paginate.currentPage = response.data.data.current_page;
          }
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
        });
      this.$bus.$emit("show-loading", false);
    },
  },

  created() {
    this.listAll();
  },
};
</script>
